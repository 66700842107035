<template>
  <div id="experience">
    <h2>Experience</h2>
    <div class="inner">
      <h3>Maharashtra Knowledge Corporation of India Pvt Ltd</h3>
      <div class="inner-desc">
       <h4>August 2021 - August 2022</h4>
       <strong> Worked on: Golang and VueJS </strong>
       <h4>What I built?</h4>
       <ul>
         <li> CoreStudio: A low-code backend development platform</li>
         <li> ReportTool: Run report generation queries on any type of database and huge amount of data</li>
       </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Exp',
  data() {
    return {
      show: -1
    }
  }
}
</script>

<style scoped>

.reader {
  width: 100%;
  padding: 30px 30px;
  border-radius: 0 0 2rem 0;
  animation: ease-in;
  animation-duration: 2s;
}

h3 {
  /*
  animation: ease;
  animation-duration: 2s;
  */
}

ul {
  margin-left: 20px;
}
.inner {
  margin-top: 30px;
}

.inner-desc {
  margin: 10px;
  /*
  animation: slide;
  animation-duration: 2s;
  */
}

@keyframes ease {
  from { opacity: 0 }
  to { opacity: 1; }
}

@keyframes slide {
  from { margin-top: 10px; }
  to { margin-top: 0px; }
}

</style>
